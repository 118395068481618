




















import Vue from 'vue';

import { views } from '@/router';

import PageHeaderItemButtonLocale from '@/components/PageHeaderItemButtonLocale.vue';
import PageHeaderItem from '@/components/PageHeaderItem.vue';
import NavList from '@/components/NavList.vue';
import NavListItem from '@/components/NavListItem.vue';
import BaseLinkRouter from '@/components/BaseLinkRouter.vue';
import BaseTransition from '@/components/BaseTransition.vue';

export default Vue.extend({
  name: 'PageHeader',

  components: {
    PageHeaderItemButtonLocale,
    PageHeaderItem,
    NavList,
    NavListItem,
    BaseLinkRouter,
    BaseTransition,
  },

  data() {
    return {
      views,
    };
  },
});
