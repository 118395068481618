






import Vue from 'vue';

export default Vue.extend({
  name: 'NavListItem',

  props: {
    bare: { type: Boolean, default: false },
  },
});
