






import Vue from 'vue';
import { Location } from 'vue-router';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  name: 'BaseLinkRouter',

  props: {
    route: {
      type: Object,
      required: true,
      validator(value) {
        const hasName = typeof value.name === 'string';
        return hasName;
      },
    },
  },

  computed: {
    text(): TranslateResult {
      const translationKey = `view.${this.route.name}`;
      return this.$t(translationKey);
    },

    target(): Location {
      return {
        name: this.route.name,
        params: {
          locale: this.$i18n.locale,
        },
      };
    },
  },
});
