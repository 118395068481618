






import Vue from 'vue';

import BaseList from '@/components/BaseList.vue';

export default Vue.extend({
  name: 'NavList',

  components: {
    BaseList,
  },

  props: {
    column: { type: Boolean, default: false },
  },
});
