







import Vue from 'vue';

import PageHeader from '@/components/PageHeader.vue';

export default Vue.extend({
  name: 'App',

  components: {
    PageHeader,
  },

  metaInfo() {
    return {
      titleTemplate: `${this.$t('my.name')} · %s`,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: 'description',
          content: this.$t('my.intro') as string,
        },
      ],
    };
  },
});
